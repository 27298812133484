import Image, { StaticImageData } from "next/image";
import { Route } from "@/routers/types";
import imageRightPng from "@/images/hero-right.png";
import imageRightPng2 from "@/images/hero-right-2.png";
import imageRightPng3 from "@/images/hero-right-3.png";

interface Hero2DataType {
  image: StaticImageData | string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: Route;
}

export const HERO2_DEMO_DATA: Hero2DataType[] = [
  {
    image: imageRightPng2,
    heading: "Collection exclusive <br /> à saisir",
    subHeading: "🎉 Profitez de réductions sur nos meilleures ventes ! 🎉",
    btnText: "Shoppez maintenant",
    btnLink: "/collection",
  },
  {
    image: imageRightPng3,
    heading: "Offres spéciales <br /> pour vous",
    subHeading: "Cette saison, dénichez les meilleures affaires 🔥",
    btnText: "Découvrir",
    btnLink: "/collection",
  },
  {
    image: imageRightPng,
    heading: "Nouveautés <br /> en ligne",
    subHeading: "Ne manquez pas les tendances du moment 🔥",
    btnText: "Explorer",
    btnLink: "/collection",
  },
];

