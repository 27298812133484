"use client";
import { useState } from 'react';
import Nav from '@/shared/Nav/Nav';
import NavItem from '@/shared/NavItem/NavItem';
import Category from '@/models/Category';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import HeaderFilterSearchPage from './HeaderFilterSearchPage';
import Heading from './Heading/Heading';

export interface CategoryFilterProps {
  updateProducts: (categoryId: number) => any
}


const CategoryFilter: FC<CategoryFilterProps> = ({ updateProducts }) => {
  const [tabActive, setTabActive] = useState("All items");

  const handleSelectedCategory = (category: { id: number, name: string }) => {
    setTabActive(category.name)
    updateProducts(category.id)
  }

  const fetchCategories = async () => {
    const response = await Category.index();
    return [{ name: "All items" }, ...response.data];
  };

  const { data: categories = [], isLoading: isCategorieLoading, isError } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories
  });


  return (
    <Nav
      className="sm:space-x-2"
      containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
    >
      {!isCategorieLoading && categories.map(
        (item, index) => (
          <NavItem
            key={index}
            isActive={tabActive === item.name}
            onClick={() => handleSelectedCategory(item)}
          >
            {item.name}
          </NavItem>
        )
      )}

    </Nav>
  )
}

export default CategoryFilter