export const DEMO_DATA = [
  {
    id: 1,
    clientName: "Tiana Abie",
    content:
      "Produits de grande qualité, prix abordables, livraison rapide et amicale. Je recommande vivement.",
  },
  {
    id: 2,
    clientName: "Lennie Swiffan",
    content:
      "Service exceptionnel ! Les produits sont exactement comme décrits et livrés dans les délais. Très satisfait.",
  },
  {
    id: 3,
    clientName: "Berta Emili",
    content:
      "Livraison ultra-rapide et produits de très bonne qualité. Le service client est également très réactif. Un grand merci !",
  },
];


