"use client";

import React, { FC, useEffect, useState } from "react";
import HeaderFilterSection from "@/components/HeaderFilterSection";
import ProductCard from "@/components/ProductCard";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import { Skeleton } from "@/components/ui/skeleton";
import Product from "@/models/Products";
import Heading from "./Heading/Heading";
import CategoryFilter from "./CategoryFilter";
import Link from "next/link";

export interface SectionGridFeatureItemsProps { }

const SectionGridFeatureItems: FC<SectionGridFeatureItemsProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 8,
    search: "",
    categoryId: undefined,
  });

  // Fonction pour récupérer les produits
  const fetchProducts = async (params: any) => {
    try {
      const response = await Product.index(params);
      return response.data.products
        .filter((product: any) => product.images.length)
        .filter((product: any) => product.active);
    } catch (error) {
      console.error("Erreur lors de la récupération des produits :", error);
      return [];
    }
  };

  // Chargement initial et mise à jour des produits
  const loadProducts = async () => {
    setIsLoading(true);
    try {
      const newProducts = await fetchProducts(filters);
      setProducts((prevProducts) =>
        filters.page === 1 ? newProducts : [...prevProducts, ...newProducts]
      );
      setHasMore(newProducts.length === filters.pageSize);
    } catch (error) {
      console.error("Erreur lors du chargement des produits :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (key: string, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
      page: key === "page" ? value : 1, // Réinitialise la page si ce n’est pas une pagination
    }));
  };

  // Fonction pour gérer la catégorie
  const handleCategoryChange = (categoryId: number) => {
    handleFilterChange("categoryId", categoryId);
  };

  useEffect(() => {
    loadProducts();
  }, [filters]);;

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <div className="nc-SectionGridFeatureItems relative">
      <div>
        <Heading>{`Tendances du moment`}</Heading>
        <div className="mb-8">
          <CategoryFilter updateProducts={handleCategoryChange} />
        </div>
      </div>

      <div className={`grid gap-8 grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
        {!isLoading &&
          products.map((item, index) => <ProductCard data={item} key={index} />)}

        {isLoading &&
          Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="w-full flex flex-col space-y-3">
              <Skeleton className="h-[260px] w-full rounded-xl" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
              </div>
            </div>
          ))}
      </div>

      {hasMore && (
        <div className="flex mt-16 justify-center items-center">
          <Link href={"/collection"}>
            <ButtonPrimary>
              Afficher plus
            </ButtonPrimary>
          </Link>
        </div>
      )}
    </div>
  );
};

export default SectionGridFeatureItems;
