import api from "@/services/api";
const base = "/categories";

const Category = {
  index: (params: { page?: number; pageSize?: number; search?: string } = {}) => {
    const queryParams = new URLSearchParams();

    if (params.page) queryParams.append("page", params.page.toString());
    if (params.pageSize) queryParams.append("pageSize", params.pageSize.toString());
    if (params.search) queryParams.append("search", params.search);

    const queryString = queryParams.toString() ? `?${queryParams.toString()}` : "";

    return api("GET", `${base}${queryString}`, "", `getting categories list`);
  },
  getCategory: (id: string) =>
    api("GET", `${base}/${id}`, "", `getting single category`),
  create: (payload: any) =>
    api("POST", `${base}`, payload, `creating category`),
  update: (id: string, payload: any) =>
    api("PUT", `${base}/${id}`, payload, `updating category`),
  delete: (id: string) =>
    api("DEL", `${base}/${id}`, "", `deleting category`),
};

export default Category;

